<template>
    <div class="team">
        <div class="banner">
            <img src="@/assets/images/team-banner.png" class="banner-img"/>
        </div>

        <div class="team-info">
            <div class="info">
                <div class="title">Our Team</div>
                <div class="subtitle">ApexMed Ventures’s team of numerous seasoned investment professionals brings extensive expertise in operating, strategic, and financial management to all our investments.</div>
            </div>
        </div>

        <div class="team-user">
            <div class="item">
                <img src="@/assets/images/team-1.png"/>
                <div class="u-box-text">
                    <div class="u-text">
                        <div class="name">Tony Tamer</div>
                        <div class="note">Founder & Managing Partner</div>
                    </div>
                </div>
            </div>

            <div class="item">
                <img src="@/assets/images/team-2.png"/>
                <div class="u-box-text">
                    <div class="u-text">
                        <div class="name">Rick Rosen</div>
                        <div class="note">VP of Finance</div>
                    </div>
                </div>
            </div>

            <div class="item">
                <img src="@/assets/images/team-3.png"/>
                <div class="u-box-text">
                    <div class="u-text">
                        <div class="name">Bruce Robertson</div>
                        <div class="note">Analyst</div>
                    </div>
                </div>
            </div>

            <div class="item">
                <img src="@/assets/images/team-4.png"/>
                <div class="u-box-text">
                    <div class="u-text">
                        <div class="name">Gwen Watanabe</div>
                        <div class="note">Marketing and Operations</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.team-info {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 405px;

    background-color: #FFFFFF;

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 1064px;
        height: 155px;
    }

    .title {
        font-size: 48px;
        line-height: 31px;
        font-weight: 300;
        color: #050505;
    }

    .subtitle {
        font-size: 22px;
        line-height: 38px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 1px;
        color: #1E1E1E;
    }
}

.team-user {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 625px;
    padding: 95px 60px 95px 60px;
    background-color: #F1F2F7;

    .item {
        display: flex;
        flex-direction: column;

        width: 316px;

        > img {
            width: 100%;
            height: 290px;
        }

        .u-box-text {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 144px;
            background-color: #FFFFFF;
        }

        .u-text {
            width: 273px;
            height: 74px;

            > .name {
                font-size: 20px;
                font-weight: 600;
                line-height: 31px;
                color: #050505;
            }

            > .note {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 1px;
                line-height: 41px;
                color: #3B7099;
                text-transform: Uppercase;
            }
        }
    }
}
</style>